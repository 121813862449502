import { Grid, Button, Tooltip } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import React,{ useEffect, useState } from "react";
import userManagementIcon from "../../assets/icons/user-management.svg";
import fleetManagementIcon from "../../assets/icons/fleet-management.svg";
import bookingManagementIcon from "../../assets/icons/booking-management.svg";
import airportManagementIcon from "../../assets/icons/airport-management.svg";
import couponsManagementIcon from "../../assets/icons/coupons-management.svg";
import emptyLegManagementIcon from "../../assets/icons/empty-leg-management.svg";
import flightsManagementIcon from "../../assets/icons/flights-management.svg";
import manageBMJRewardIcon from "../../assets/icons/manage-bmj-reward-points.svg";
import offeredFlightManagementIcon from "../../assets/icons/offered-flight-management.svg";
import quotationManagementIcon from "../../assets/icons/quotation-management.svg";
import operatorManagementIcon from "../../assets/icons/operator-management.svg";
import queryManagementIcon from "../../assets/icons/query-management.svg";
import feedManagementIcon from "../../assets/icons/feed-management-icon.svg";
import bookingStatsIcon from "../../assets/icons/bookig-stats.svg";
import blogsManagementIcon from "../../assets/icons/blogs-management-icon.svg";
import messengerIcon from "../../assets/icons/messenger-icon.svg";
import adminIcon from "../../assets/icons/admin-icon.svg";
import instantQuotationIcon from  "../../assets/icons/instant-quotaition-icon.svg";
// import { log } from "console";
import { sessionUtility } from "../../utility/sessionUtility";

// const useStyles = makeStyles((theme?: any) => ({
//   btm: {
//     bottom: "0",
//     position: "fixed",
//   },
// }));

export const NavigationList: React.FC<any> = () => {

  // const [selectedTab, setSelectedTab] = useState("users-management");
  const [selectedTab, setSelectedTab] = useState("");
  const navigate = useNavigate();

  const userDetails: any = sessionUtility.getAuthentication().session;

  // console.log(userDetails?.role[0].authority);

  // const isOperator = userDetails?.role[0]?.authority === "ROLE_OPERATOR:EDIT";

  const isOperator = userDetails?.role[0]?.authority === "ROLE_OPERATOR:EDIT" ? true :false;
  // const handleClick = () => {
  //   setOpenJobMenu(!openJobMenu);
  // };
  // const handleCustomerClick = () => {
  //   setOpenCustomerMenu(!openCustomerMenu);
  // };

  const adminSideBarListItems = [
    {
      title: "User Management",
      icon: userManagementIcon,
      // link: "users-management",
      link: "",
    },
    {
      title: "Fleet Management",
      icon: fleetManagementIcon,
      link: "fleets-management",
    },
    {
      title: "Airport Management",
      icon: airportManagementIcon,
      link: "airports-management",
    },
    {
      title: "Quotation Management",
      icon: quotationManagementIcon,
      link: "quotation-management",
    },
    {
      title: "Booking Management",
      icon: bookingManagementIcon,
      link: "bookings-management",
    },

    // {
    //   title: 'Coupons Management',
    //   icon: couponsManagementIcon,
    //   link: "coupons-management"
    // },

    {
      title: "Manage BMJ Reward Points",
      icon: manageBMJRewardIcon,
      link: "bmj-reward-points",
    },
    {
      title: "Booking Stats",
      icon: bookingStatsIcon,
      link: "booking-stats",
    },
    
    {
      title: "Offered flight Management",
      icon: offeredFlightManagementIcon,
      link: "offered-flight-management",
    },
    {
      title: "Empty Leg Management",
      icon: emptyLegManagementIcon,
      link: "empty-leg-management",
    },
    {
      title: "Operator Management",
      icon: operatorManagementIcon,
      link: "operator-management",
    },
    {
      title: 'Query Management',
      icon: queryManagementIcon,
      link: "query-management"
    },
    {
      title: "Feeds Management",
      icon: feedManagementIcon,
      link: "feeds-management",
    },
    {
      title: "Blogs Management",
      icon: blogsManagementIcon,
      link: "blogs-management",
    },
    {
      title: "Mails",
      icon: messengerIcon,
      link: "mails",
    },
    {
      title: "Instant Quotation Management",
      icon: instantQuotationIcon,
      link: "instant-quotation-management",
    },

    //{
    //   title: 'Flights Management',
    //   icon: flightsManagementIcon,
    //   link: "flights-management"
    // },
  ];
  const operatorSideBarListItems = [
    {
      title: "My Aircrafts",
      icon: operatorManagementIcon,
      // link: "operator-my-aircrafts",
      link: "",
    },
    //{
    //   title: 'Flights Management',
    //   icon: flightsManagementIcon,
    //   link: "flights-management"
    // },
  ];

  const handleListItem = ({  link }: any) => {
    setSelectedTab(link);
    navigate(link)
    // window.location.href=link
  }

  const location = useLocation();
  const pathName = location.pathname.split("/");

  useEffect(()=>{
    setSelectedTab(pathName[1])
  },[pathName])

  return (
    <Grid container className="sidebar-list" sx={{ minHeight: "80vh" }}>
      <Grid item md={12} className="list-item" mb="10px">
        <Button onClick={()=>navigate("/")}>
          <img
            src={adminIcon}
            alt="superadmin icon"
            height="40px"
            width="40px"
          />
        </Button>
      </Grid>

      {isOperator
        ? operatorSideBarListItems.map((item) => (
            <Grid item md={12} key={item.title} className="list-item">
              <Tooltip title={item.title} placement="right">
                <Button
                  className={`${selectedTab === item.link && "selectedTab"}`}
                  onClick={() => handleListItem(item)}
                >
                  <img
                    src={item.icon}
                    alt={item.title}
                    height="100%"
                    width="100%"
                  />
                </Button>
              </Tooltip>
            </Grid>
          ))
        : adminSideBarListItems.map((item) => (
            <Grid item md={12} key={item.title} className="list-item">
              <Tooltip title={item.title} placement="right">
                <Button
                  className={`${selectedTab === item.link && "selectedTab"}`}
                  onClick={() => handleListItem(item)}
                >
                  <img
                    src={item.icon}
                    alt={item.title}
                    height="100%"
                    width="100%"
                  />
                </Button>
              </Tooltip>
            </Grid>
          ))}
    </Grid>
  );
};
